import React from "react";
import PageWrapper from "../components/page-wrapper";
import Shard from "../components/shard";
import "../styles/pages/material-catalogue.scss";
import shards from "../data/shards.yml";
import { motion, useAnimation } from "framer-motion";
import { delayed, growRight, fadeIn } from "../components/animations";

const gridAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 1,
      delayChildren: 0.75,
      staggerChildren: 0.1,
    },
  },
};

function MaterialsCatalogue() {
  return (
    <>
      <PageWrapper>
        <main id="material-catalogue">
          <div className="container">
            <motion.h1
              variants={delayed(0.5, -200)}
              initial="hidden"
              animate="visible"
            >
              Materials<span>.</span>
            </motion.h1>
            <motion.h2 variants={fadeIn(1)} animate="visible" initial="hidden">
              All of our options, supplied by Berridge.
            </motion.h2>
            <section className="shard-section">
              <motion.h2
                variants={fadeIn(0.75)}
                animate="visible"
                initial="hidden"
              >
                Standard Colors
              </motion.h2>
              <motion.div
                variants={gridAnimation}
                initial="hidden"
                animate="visible"
                className="shards-grid"
              >
                {shards["standard"].map((shard) => (
                  <Shard name={shard.name} hex={shard.hex} />
                ))}
              </motion.div>
            </section>
            <section className="shard-section">
              <motion.h2
                variants={fadeIn(0.75)}
                animate="visible"
                initial="hidden"
              >
                Premium Colors
              </motion.h2>
              <motion.div
                variants={gridAnimation}
                initial="hidden"
                animate="visible"
                className="shards-grid"
              >
                {shards["premium"].map((shard) => (
                  <Shard name={shard.name} hex={shard.hex} />
                ))}
              </motion.div>
            </section>
            <section className="shard-section">
              <motion.h2
                variants={fadeIn(0.75)}
                animate="visible"
                initial="hidden"
              >
                Metallic Colors
              </motion.h2>
              <motion.div
                variants={gridAnimation}
                initial="hidden"
                animate="visible"
                className="shards-grid"
              >
                {shards["metallic"].map((shard) => (
                  <Shard name={shard.name} hex={shard.hex} />
                ))}
              </motion.div>
            </section>
            <section className="shard-section">
              <motion.h2
                variants={fadeIn(0.75)}
                animate="visible"
                initial="hidden"
              >
                Natural Metal Finishes
              </motion.h2>
              <motion.div
                variants={gridAnimation}
                initial="hidden"
                animate="visible"
                className="shards-grid"
              >
                {shards["natural-metallic"].map((shard) => (
                  <Shard name={shard.name} hex={shard.hex} />
                ))}
              </motion.div>
            </section>
          </div>
        </main>
      </PageWrapper>
    </>
  );
}

export default MaterialsCatalogue;
